import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { Link, MaxContainer } from '@/components/atoms';
import styles from './headerlite.module.scss';
import { checkoutRestore } from '@/redux/checkout/checkoutReducer';
import BrandImage from '@/components/atoms/BrandImage';
import { destroyCookie } from '@/services/cookies';

const HeaderLite = ({ handleClick }) => {
  const { logged } = useSelector((state) => state.checkout);
  const router = useRouter();
  const dispatch = useDispatch();
  const goHome = () => {
    if (!logged && !router.pathname.includes('finish')) {
      destroyCookie('token');
      destroyCookie('spsToken');
      destroyCookie('refreshToken');
      dispatch(checkoutRestore());
    }
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <div className={styles.header}>
      <MaxContainer>
        <div className={styles.logoContainer}>
          <Link
            href="/"
            passHref
            handleClick={goHome}
          >
            <div className={styles.firstLine}>
              <BrandImage
                primary
                height={23}
              />
            </div>
          </Link>
        </div>
      </MaxContainer>
    </div>
  );
};

HeaderLite.propTypes = {
  handleClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};
HeaderLite.defaultProps = {
  handleClick: false
};

export default HeaderLite;
